import React,{useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import { axiosInstance } from '../../config';
import "./widget.scss"

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';

const Widget = ({ type }) => {
    let data;

    const [amt, setAmt] = useState();
    const [usr, setUsr] = useState();

    useEffect(()=>{
        const getUsers = async() => {
            try{
                const result = await axiosInstance.get("/clients/active", {withCredentials: true});
                setUsr(result)
            }catch(err){
                console.log(err);
            }
        }
        getUsers();
    },[])

    useEffect(()=>{
        const getAmt = async() => {
            try{
                const res = await axiosInstance.get("/razorpay/collection", {withCredentials: true});
                setAmt(res);
            }catch(err){
                console.log(err);
            }
        }
        getAmt();
    },[])

    switch(type){    
        case "clients":
            data = {
                title: "TOTAL ACTIVE CLIENTS",
                isMoney: false,
                link: "see all clients",
                icon: (<PersonOutlineOutlinedIcon className='icon' style={{backgroundColor: "#dc3545"}}/>)
            };
            break;
        case "earnings": 
            data = {
                title: "WEEKLY EARNINGS",
                isMoney: true,
                link: "view earnings",
                icon: (<MonetizationOnOutlinedIcon className='icon' style={{backgroundColor: "yellow", color: "black"}}/>)
            };
            break;
        default:
            break;
    }

  return (
    <div className='widget'>
        <div className="left">
            <span className="title">{data.title}</span>
            {
                !amt && <div className='spinner'></div>
            }
            {amt && <span className="counter">{data.isMoney && "₹"}{data.isMoney && amt.data}{!data.isMoney && usr.data}</span>}
            {!data.isMoney && <Link to='clients' style={{color: "black"}}><span className="link">{data.link}</span></Link>}
            {data.isMoney && <Link to='transactions' style={{color: "black"}}><span className="link">{data.link}</span></Link>}
        </div>
        <div className="right">
            <div className='percentage positive'>
                {/* <ArrowDropUpOutlinedIcon/> {percent}% */}
            </div>
            {data.icon}
        </div>
    </div>
  )
}

export default Widget
