import React from 'react'
import "./navbar.scss"

import SearchIcon from '@mui/icons-material/Search';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';

const Navbar = () => {
  return (
    <div className='navbar'>
      <div className='wrapper'>
        <div className='search'>
          <input type="text" placeholder='Search...' />
          <SearchIcon className='icon'/>
        </div>
        <div className='items'>
          <div className='item'>
            <LanguageOutlinedIcon className='i'/>
            English
          </div>
          <div className="item">
            <NotificationsOutlinedIcon className='i'/>
            <div className="counter">1</div>
          </div>
          <div className="item">
            <ListOutlinedIcon className='i'/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar