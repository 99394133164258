import React, { useMemo } from 'react'
import "./chart.scss"
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useState } from 'react';
import { useEffect } from 'react';
import { axiosInstance } from '../../config';

const Chart = () => {

  const [clientStats, setClientStats] = useState([]);

  const Months = useMemo(
    () => [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ], 
    []
  )

  useEffect(() => {
    const getStats = async() =>{
      try{
        const res = await axiosInstance.get("/clients/stats", {withCredentials: true});
        res.data.sort((a,b) => a._id > b._id ? 1 : -1).map((item) => 
            setClientStats((prev) => [
              ...prev,
              { name: Months[item._id - 1], "Clients": item.total }
            ])
        )
      }catch(err){
        console.log(err);
      }
    }
    getStats();
  }, [Months]);

  return (
    <div className='charts'>
        <p>LAST YEAR USERS STATS</p>
        <ResponsiveContainer width="100%" height="100%">
        <AreaChart width={730} height={250} data={clientStats}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <defs>
                <linearGradient id="total_users" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                </linearGradient>
            </defs>
            <XAxis dataKey="name" stroke='gray'/>
            <YAxis stroke='gray'/>
            <CartesianGrid strokeDasharray="3 3" className='grid' />
            <Tooltip />
            <Area type="monotone" dataKey="Clients" stroke="#8884d8" fillOpacity={1} fill="url(#total_users)" />
        </AreaChart>
        </ResponsiveContainer>
    </div>
  )
}

export default Chart
