import Home from "./pages/home";
import Login from "./pages/login";
import List from "./pages/list";
import Single from "./pages/single";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SideNav from "./components/sidebar";
import Navbar from "./components/navbar";
import "./app.scss"
import { useContext, useState } from "react";
import { AuthContext } from "./authContext";
import Transaction from "./pages/transactions";

function App() {

  const [dark, setDark] = useState(false);
  const [nav, setNav] = useState(false);

  const ProtectedRoute = ({children}) =>{
    const { user } = useContext(AuthContext)

    if(!user){
      setNav(false);
      return <Navigate to="/login"/>
    }

    setNav(true);
    return children;
  }

  return (
    <div className={dark ? 'App dark' : 'App'}>
      <BrowserRouter>
        {nav && <SideNav setDark={setDark}/>}
        <div className="container">
          {nav && <Navbar setDark={setDark}/>}
          <Routes>
            <Route path="/login" element={<Login/>} />
            
            <Route path="/" element={
              <ProtectedRoute>
                <Home/>
              </ProtectedRoute>
            } />
            <Route path="clients">
                <Route index element={
                  <ProtectedRoute>
                    <List/>
                  </ProtectedRoute>
                } />
                <Route path=":clientId" element={
                  <ProtectedRoute>
                    <Single/>
                  </ProtectedRoute>
                } />
            </Route>
            <Route path="transactions" element={
              <ProtectedRoute>
                <Transaction/>
              </ProtectedRoute>
            }/>
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;