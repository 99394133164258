import React,{ useState } from 'react'
import "./single.scss"
import { Link } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { active } from '../../redux/dataSlice';
import { axiosInstance } from '../../config';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import SchoolIcon from '@mui/icons-material/School';
import MessageIcon from '@mui/icons-material/Message';
import PendingIcon from '@mui/icons-material/Pending';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Spinner from '../../components/spinner';

const Single = () => {

  const [info, setInfo] = useState();
  const [amt, setAmt] = useState("");
  const [curr, setCurr] = useState("INR");
  const [cc, setCC] = useState("+91");
  const [done, setDone] = useState(false);
  const [updated, setUpdated] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.value);

  const handleChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try{
        const updatedClient = { ...info }
        const update = await axiosInstance.put(`/clients/${data._id}`, updatedClient, {withCredentials: true})
        dispatch(active(update.data))
        setUpdated(true);
    }catch(err){
        console.log(err);
    }
  }

  const handlePayment = async () => {
    try{
      setDone(true);
      await axiosInstance.post(`/razorpay/payment`, {
        "amount": amt,
        "currency": curr,
        "name": data.name,
        "email": data.email,
        "contact": cc + data.phone
      }, {withCredentials: true})
    }
    catch(err){
      console.log(err);
    }
  }

  return (
    <div>
      {
        !data && <Spinner/>
      }
    {data && 
    <div className='single'>
        <div className='top'>
          <p>Client Details</p>
          <Link to='/clients'> 
            <button className='Btn'>Back</button>
          </Link>
        </div>
        <div className="client">
          <div className="userShow">
            <div className="userShowTop">
              <div className="userShowTopTitle">
                <span className="userShowFullname">{data.name}</span>
                {/* <span className="userShowCourse">Bachelors</span> */}
              </div>
            </div>
            <div className="userShowBottom">
              <span className="userShowTitle">Account Details</span>
              <div className="userShowInfo">
                <PermIdentityIcon className="userShowIcon" />
                <span className="userShowInfoTitle">{data.gender}</span>
              </div>
              <div className="userShowInfo">
                <PendingIcon className="userShowIcon" />
                <span className="userShowInfoTitle">{data.status}</span>
              </div>
              <div className="userShowInfo">
                <CalendarTodayIcon className="userShowIcon" />
                <span className="userShowInfoTitle">{data.DOB?.slice(0,10)}</span>
              </div>
              <span className="userShowTitle">Contact Details</span>
              <div className="userShowInfo">
                <PhoneAndroidIcon className="userShowIcon" />
                <span className="userShowInfoTitle">{data.phone}</span>
              </div>
              <div className="userShowInfo">
                <MailOutlineIcon className="userShowIcon" />
                <span className="userShowInfoTitle">{data.email}</span>
              </div>
              <div className="userShowInfo">
                <AccessTimeIcon className="userShowIcon" />
                <span className="userShowInfoTitle">{data.time}</span>
              </div>
              <div className="userShowInfo">
                <LocationSearchingIcon className="userShowIcon" />
                <span className="userShowInfoTitle">{data.city} | {data.country}</span>
              </div>
              <span className="userShowTitle">Interests</span>
              <div className="userShowInfo">
                <LocationCityIcon className="userShowIcon" />
                <span className="userShowInfoTitle">{data.dream}</span>
              </div>
              <div className="userShowInfo">
                <SchoolIcon className="userShowIcon" />
                <span className="userShowInfoTitle">{data.degree} ({data.stream})</span>
              </div>
              <div className="userShowInfo">
                <MessageIcon className="userShowIcon" />
                <span className="userShowInfoTitle">Message:</span>
              </div>
              <span className="userShowInfoTitle">{data.message}</span>
            </div>
          </div>
          <div className="userUpdate">
            <span className="userUpdateTitle">EDIT</span>
            {
              updated &&
              <p className='updated'>Details Updated Successfully!</p>
            }
            <form className="userUpdateForm">
              <div className="userUpdateLeft">
                <div className="userUpdateItem">
                  <label>Full Name</label>
                  <input
                    type="text"
                    id = "name"
                    onChange={handleChange}
                    placeholder={data.name}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Email</label>
                  <input
                    type="text"
                    id = "email"
                    onChange={handleChange}
                    placeholder={data.email}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Phone</label>
                  <input
                    type="text"
                    id = "phone"
                    onChange={handleChange}
                    placeholder={data.phone}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Home City</label>
                  <input
                    type="text"
                    id = "city"
                    onChange={handleChange}
                    placeholder={data.city}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Home Country</label>
                  <input
                    type="text"
                    id = "country"
                    onChange={handleChange}
                    placeholder={data.country}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Dream Country</label>
                  <input
                    type="text"
                    id = "dream"
                    onChange={handleChange}
                    placeholder={data.dream}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Contact Time</label>
                  <input
                    type="text"
                    id = "time"
                    onChange={handleChange}
                    placeholder={data.time}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Stream</label>
                  <input
                    type="text"
                    id = "stream"
                    onChange={handleChange}
                    placeholder={data.stream}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Date Of Birth</label>
                  <input
                    type="date"
                    id = "DOB"
                    onChange={handleChange}
                    className="userUpdateInput"
                  />
                </div>
                <div className="userUpdateItem">
                  <label>Status</label>
                  <select onChange={handleChange} id="status">
                    <option>Select</option>
                    <option>Active</option>
                    <option>Pending</option>
                    <option>Discarded</option>
                  </select>
                </div>
                <button className="userUpdateButton" onClick={handleUpdate}>Update</button>
              </div>
            </form>
          </div>
        </div>
    <div className='payment'>
      {!done &&
      <div className='box'>
      <div>
        <label>Enter Amount: ₹ </label>
        <input
          type="number"
          id = "amt"
          onChange={(e)=> setAmt(e.target.value)}
          className="userUpdateInput"
          />
      </div>
      <div>
        <label>Country Code(Mobile):  </label>
        <input
          type="text"
          id = "cc"
          onChange={(e)=> setCC(e.target.value)}
          className="userUpdateInput"
          placeholder='+91'
          />
      </div>
      <div>
        <label>Enter Currency: </label>
        <input
          type="text"
          id = "curr"
          onChange={(e)=> setCurr(e.target.value)}
          className="userUpdateInput"
          placeholder='INR'
          />
      </div>
      <button className='btn' disabled={!amt} onClick={()=> handlePayment()} type='submit'>Send Payment Link</button>
      </div>
      }
      { 
        done &&
        <div className='done'>
          Payment Link of ₹{amt} Sent Successfully!
        </div>
      }
    </div>
    </div>
    }
    </div>
  )
}

export default Single
