import React, { useState } from 'react'
import "./datatable.scss"
import { Link } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import useFetch from '../../hooks/useFetch'
import { useEffect } from 'react';
import { axiosInstance } from '../../config';
import { useDispatch } from 'react-redux';
import { active } from '../../redux/dataSlice';
import Spinner from '../spinner';

const columns = [
    {
        field: 'name',
        headerName: 'Full Name',
        width: 200,
    },
    { field: 'country', headerName: 'Home Country', width: 160 },
    { field: 'dream', headerName: 'Dream Country', width: 160 },
    { field: 'createdAt', headerName: 'Date', type: 'date', width: 140 },
    {
        field: 'status',
        headerName: 'Status',
        width: 140,
        renderCell: (params) =>{
            return (<div className={`cellWithStatus ${params.row.status}`}>{params.row.status}</div>);
        }
    },
];

const DataTable = () => {

    const [list, setList] = useState();
    const {data} = useFetch("https://api.trgoverseas.com/admin/clients")
    const dispatch = useDispatch();

    useEffect(() => {
        setList(data);
    },[data])

    const handleDelete = async (id) => {
        try{
            await axiosInstance.delete(`/clients/${id}`, {withCredentials: true})
            setList(list.filter((item) => item._id !== id))
        }catch(err){
            console.log(err);
        }
    }

    const handleView = async (id) => {
        try{
            const single = await axiosInstance.get(`/clients/${id}`, {withCredentials: true})
            dispatch(active(single.data));
        }catch(err){
            console.log(err);
        }
    }

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params)=>{
                return(
                    <div className='cellAction'>
                        <Link to={`${params.row._id}`} style={{textDecoration:'none'}}>
                            {/* <div className='viewBtn'>VIEW</div> */}
                            <div className='viewBtn' onClick={async() => await handleView(params.row._id)}>VIEW</div>
                        </Link>
                        <div className='deleteBtn' onClick={() => handleDelete(params.row._id)}>DELETE</div>
                    </div>
                )
            }
        }
    ]
  return (
    <div className='data-table'>
        {
            !list && <Spinner/>
        }
        
        {list && <DataGrid
            className='dataGrid'
            rows = {list}
            columns={columns.concat(actionColumn)}
            pageSize={10}
            rowsPerPageOptions={[10]}
            checkboxSelection
            getRowId={row => row._id}
        />}
    </div>
  )
}

export default DataTable
