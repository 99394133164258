import "./table.scss";
import React,{useEffect, useState} from 'react'
import { axiosInstance } from "../../config";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from '@mui/material/TablePagination';
import Spinner from "../spinner";

const Transactions = () => {
  const [Rows, setRows] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    async function fetchData() {
      try {
          const res = await axiosInstance.get('/razorpay', {withCredentials: true}); 
          setRows(res.data);
      } catch (err) {
          console.log(err);
      }
    }
    fetchData();
  },[]);
  
  return (
    <div className="trans">
    <p>RECENT TRANSACTIONS</p>
    {
      !Rows && <Spinner/>
    }
    {Rows && <Paper>
    <TableContainer className='homeTable'>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell head">Tracking ID</TableCell>
            <TableCell className="tableCell head">Client</TableCell>
            <TableCell className="tableCell head">Updated On</TableCell>
            <TableCell className="tableCell head">Amount</TableCell>
            <TableCell className="tableCell head">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((Row) => {
            
            var a = new Date(Row.updated_at * 1000);
            var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            var month = months[a.getMonth()];
            var date = a.getDate();
            return(
            <TableRow key={Row.id}>
              <TableCell className="tableCell">{Row.id}</TableCell>
              <TableCell className="tableCell">{Row.customer.name}</TableCell>
              <TableCell className="tableCell">{date + ' ' + month}</TableCell>
              <TableCell className="tableCell amt">₹{Row.amount/100}</TableCell>
              <TableCell className="tableCell">
                <span className={`status ${Row.status}`}>{Row.status}</span>
              </TableCell>
            </TableRow>
          )})}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
        rowsPerPageOptions={[10, 15]}
        component="div"
        count={Rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </Paper>}
    </div>
  );
};

export default Transactions;
