import React from 'react'
import "./home.scss"
import Widget from '../../components/widget'
import Chart from '../../components/chart'

const Home = () => {
  return (
      <div className='homeContainer'>
        <div className='widgets'>
          <Widget type="clients"/>
          <Widget type="earnings"/>
        </div>
        <div className="charts">
          <Chart/>
        </div>
      </div>
  )
}

export default Home