import React,{useContext} from 'react'
import "./sidebar.scss"
import { NavLink, Link } from "react-router-dom";
import { AuthContext } from "../../authContext";

import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import PaymentsIcon from '@mui/icons-material/Payments';

const SideNav = ({setDark}) => {

    const { dispatch } = useContext(AuthContext);
  return (
    <div className='sidenav'>
        <div className='top'>
            <Link to='/'>
                <img src="https://therightguru.com/static/media/pic_4.0f4802fd.png" alt="TRG Logo" />
            </Link>
        </div>
        <hr/>
        <div className='center'>
            <ul>
                <p className='title'>MAIN</p>
                <NavLink to='/' activeclassname='active'>
                    <li><DashboardIcon className='icon'/> <span>Dashboard</span></li>
                </NavLink>
                <p className='title'>LIST</p>
                <NavLink to='clients' activeclassname='active'>
                    <li><GroupIcon className='icon'/> <span>Clients</span></li>
                </NavLink>
                <NavLink to='transactions' activeclassname='active'>
                    <li><PaymentsIcon className='icon'/> <span>Transactions</span></li>
                </NavLink>
                <p className='title'>USEFUL</p>
                <li><EqualizerIcon className='icon'/> <span>Statistics</span></li>
                <li><NotificationsIcon className='icon'/> <span>Notifications</span></li>
                <p className='title'>SERVICE</p>
                <li><SettingsSystemDaydreamIcon className='icon'/> <span>System Health</span></li>
                <li><SettingsIcon className='icon'/> <span>Settings</span></li>
                <p className='title'>USER</p>
                <li><AccountCircleIcon className='icon'/> <span>Profile</span></li>
                <li onClick={()=> dispatch({type: "LOGOUT"})}><LogoutIcon className='icon'/> <span>Logout</span></li>
            </ul>
        </div>
        <div className='bottom'>
            <div className="colorOptions" onClick={()=> setDark(false)}></div>
            <div className="colorOptions" onClick={()=> setDark(true)}></div>
        </div>
    </div>
  )
}

export default SideNav